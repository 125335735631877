<template>
    <v-main>
        <Server/>
    </v-main>
</template>

<script>
  import Server from '../components/Server'

  export default {
    name: 'server',
    components: {
      Server
    }
  }
</script>
